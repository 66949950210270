import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import _ from "lodash";
import Link from "gatsby-link";

// components
import SEO from "../../components/SEO";
import Hero from "../../components/Hero";
import InsightsGrid from "../../components/InsightsGrid/InsightsGrid";
import Pagination from "../../components/Pagination";
import ContactBanner from "../../components/ContactBanner";
import GridWidget from "../../components/GridWidget";
import MostPopularPostWidget from "../../components/MostPopularPostWidget";
import SecondaryNavRes from "../../components/SecondaryNavRes";
import DefaultSharingImage from "../../static/ansira-logo-sharing.png";

const StyledInsightsIndex = styled.main`
  max-width: 1500px;
  margin: 0 auto;
  .post-area {
    grid-gap: 1.5em;
    padding-bottom: 50px;
    padding-top: 20px;
    margin-left: -2px;
    grid-template-columns: 1fr;
  }

  .insights-grid {
    grid-column: span 2;
    margin-bottom: 1.5em;
  }

  .widgets {
    -ms-grid-column: 3;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .post-area {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 3fr 1.5em 1fr;
      grid-template-columns: repeat(3, 1fr);
    }

    .insights-grid {
      margin-bottom: 0;
    }
  }
`;

/**
 * this template is used by gatsby-node.js to generate
 * paginated insights results
 * the query is in gatsby-node.js
 * @param {object} props
 */

const Insights = props => {
  const { group: posts } = props.pathContext;
  const { pathContext } = props;

  const insights_page = props.data.wordpressPage;
  const title = props.data.wordpressPage.acf.hero_headline;
  const subtitle = props.data.wordpressPage.acf.hero_sub_headline;

  const insightSEO = props.data.allWordpressPage.edges[0].node;

  return (
    <StyledInsightsIndex className="animate">
      <SEO
        isBlogPost={false}
        postData={insights_page}
        postImage={DefaultSharingImage}
      />
      <Hero
        headline={title}
        sub={subtitle}
        textcolor={colors.white}
        bgcolor="black"
      />
      <div className="page-container">
        <SecondaryNavRes
          className="second-nav"
          leftLink={"/insights/blog"}
          leftText={"Blog"}
          rightLink={"insights/white-papers"}
          rightText={"White Papers"}
          border="transparent"
        />
        <div className="post-area">
          <InsightsGrid posts={posts} />
          <div className="widgets">
            <GridWidget type="newsletter" />
            <MostPopularPostWidget />
          </div>
        </div>
        <Pagination pathContext={pathContext} pagePath="insights" />
        <ContactBanner
          headline={insights_page.acf.contact_headline}
          cta={insights_page.acf.contact_cta}
          url={insights_page.acf.contact_url}
        />
      </div>
    </StyledInsightsIndex>
  );
};

export default Insights;

export const query = graphql`
  query {
    allWordpressPage(filter: { title: { eq: "Insights" } }) {
      edges {
        node {
          acf {
            overview_headline
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
      }
    }
    wordpressPage(slug: { eq: "insights" }) {
      content
      slug
      title
      type
      content
      acf {
        hero_headline
        hero_sub_headline
        overview_headline
        contact_headline
        contact_cta
        contact_url
        seo_title
        seo_canonical
        seo_description
      }
    }
  }
`;
